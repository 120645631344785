import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

//get all bi orders
const getAllBIOrder = (params) => {
  let queryString = `order/bi/get-all-order?${
    params.method !== "all" ? params.method : ""
  }orders=created_at-DESC&limit=${params.limit}&page=${params.page}`; // /${params.limit}?page=${params.page}
  params.filters?.map((filter) => {
    queryString += `&filters[]=${filter}`;
  });
  return instance.get(queryString);
};

export const useGetAllBIOrder = (params) => {
  return useQuery(["get-all-bi-order", params], () => getAllBIOrder(params), {
    // refetchInterval: 20000,
  });
};

// get order list by userID
const getBIOrderListByUser = (userId, params) => {
  let queryString = `order/get-all-${params.method}-user/${userId}?orders=created_at-DESC&limit=${params.limit}&page=${params.page}`;
  params.filters?.map((filter) => {
    queryString += `&filters[]=${filter}`;
  });
  return instance.get(queryString);
};

export const useGetBIOrderListByUser = (userId, params) => {
  return useQuery(
    ["get-bi-order-by-user", userId, params],
    () => getBIOrderListByUser(userId, params),
    {
      enabled: !!userId,
      retry: 0,
    }
  );
};

// confirm order
const confirmBIOrder = (id) => {
  return instance.put(`order/bi/confirm-order/${id}`);
};

export const useConfirmBIOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(confirmBIOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
      queryClient.invalidateQueries("get-bi-order-by-user");
    },
  });
};

// complete order
const completeBIOrder = (id) => {
  return instance.put(`order/bi/complete-order/${id}`);
};

export const useCompleteBIOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(completeBIOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
      queryClient.invalidateQueries("get-bi-order-by-user");
    },
  });
};

// hold order
const holdBIOrder = (id) => {
  return instance.put(`order/bi/${id}/hold`);
};

export const useHoldBIOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(holdBIOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
      queryClient.invalidateQueries("get-bi-order-by-user");
    },
  });
};

// cancel order
const cancelBIOrder = (id) => {
  return instance.put(`order/bi/cancel-order/${id}`);
};

export const useCancelBIOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(cancelBIOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
      queryClient.invalidateQueries("get-bi-order-by-user");
    },
  });
};

// // return order
// const returnBIOrder = (data) => {
//   return instance.post(`order/bi/return`, data);
// };

// export const useReturnBIOrder = () => {
//   const queryClient = useQueryClient();
//   return useMutation(returnBIOrder, {
//     onSuccess: () => {
//     queryClient.invalidateQueries("get-all-bi-order");
//     queryClient.invalidateQueries("get-bi-order-by-user");
//     },
//   });
// };

//bi update order
const updateBIOrder = ({ orderID, data }) => {
  return instance.put(`order/bi/${orderID}`, data);
};

export const useUpdateBIOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBIOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
    },
  });
};

// bi delete cart
const deleteBiCart = ({ orderId, cartId }) =>
  instance.delete(`/order/bi/${orderId}/cart/${cartId}`);

export const useDeleteBiCart = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBiCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
    },
  });
};

// bi update cart
const updateBiCart = ({ orderId, cartId, data }) =>
  instance.put(`/order/bi/${orderId}/cart/${cartId}`, data);

export const useUpdateBiCart = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBiCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
    },
  });
};

// add cart
const addBiCart = ({ orderId, data }) =>
  instance.post(`/order/bi/${orderId}/cart/`, data);

export const useAddBiCart = () => {
  const queryClient = useQueryClient();
  return useMutation(addBiCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-bi-order");
    },
  });
};
