import axios from "axios";

export const rootURL = process.env.REACT_APP_ROOT_URL;
export const baseURL = rootURL + "api/backend/";
export const authRootURL = process.env.REACT_APP_AUTH_URL;
export const authURL = authRootURL + "api/auth/";

const instance = axios.create({
  // unauthorized instance
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

export const updateInstance = () => {
  instance.interceptors.request.use(
    (req) => {
      req.headers["Authorization"] = `Bearer ${localStorage.getItem("tkn")}`;
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

export const authInstance = axios.create({
  // authentication instance
  baseURL: authURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("tkn")}`,
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

export const updateAuthInstance = () => {
  authInstance.interceptors.request.use(
    (req) => {
      req.headers["Authorization"] = `Bearer ${localStorage.getItem("tkn")}`;
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
};

export const getAttachment = (url) => {
  return rootURL + url;
};

export default instance;
