import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import snackContext from "../../context/snackProvider";
import {
  useCreateVideo,
  useDelVideo,
  useGetVideoDetails,
  useUpdateVideo,
} from "../../query/youtube";
import PropTypes from "prop-types";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { responseHandler } from "../../utilities/response-handler";
import moment from "moment";
import tableOptionsStyle from "../../style/tableOptions";
import ShowError from "../../components/ShowError";

const Youtube = () => {
  const { data: youtubeVideo, isLoading, isError } = useGetVideoDetails();
  console.log(youtubeVideo);

  return (
    <>
      <Box>
        {" "}
        <CreateVideo />
      </Box>

      <Box border={"dashed"} borderColor={"#D3D3D3"} sx={{ py: 1, my: 1 }}>
        {isLoading && (
          <Stack alignItems="center" py={2} pb={4}>
            <CircularProgress />
          </Stack>
        )}
        {youtubeVideo?.data?.map?.((videoItem, index) => (
          <React.Fragment key={index}>
            <VideoBox videoItem={videoItem} />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

const CreateVideo = () => {
  const snack = React.useContext(snackContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutateAsync: mutateCreateVideo, isLoading } = useCreateVideo();

  const handleCreate = async (data) => {
    const res = await responseHandler(() => mutateCreateVideo({ ...data }));
    if (res.status) {
      snack.createSnack(res.msg);
    } else {
      snack.createSnack(res.msg, "error");
    }
  };
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #ccc",
          my: 2,
        }}
      >
        <form onSubmit={handleSubmit(handleCreate)}>
          <Grid
            container
            rowGap={1}
            alignItems={"end"}
            justifyContent={"flex-end"}
          >
            <Grid xs={12}>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: 0.5,
                }}
              >
                <span>
                  Name <b style={{ color: "red" }}>*</b>
                </span>
                <InputBase
                  sx={{
                    bgcolor: "#f3f3f3",
                    borderRadius: "4px",
                    p: 1,
                    mt: 1,
                  }}
                  placeholder={"Enter the title of video "}
                  {...register("title_en", {
                    required: true,
                  })}
                  fullWidth
                  multiline
                  rows={1}
                />
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: 0.5,
                }}
              >
                <span>
                  URL<b style={{ color: "red" }}>*</b>
                </span>
                <InputBase
                  sx={{
                    bgcolor: "#f3f3f3",
                    borderRadius: "4px",
                    p: 1,
                    mt: 1,
                  }}
                  placeholder={"Enter the url of video "}
                  {...register("url", {
                    required: true,
                  })}
                  fullWidth
                  multiline
                  rows={1}
                />
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: 0.5,
                }}
              >
                <span>Description</span>
                <InputBase
                  sx={{
                    bgcolor: "#f3f3f3",
                    borderRadius: "4px",
                    p: 1,
                    mt: 1,
                  }}
                  placeholder={"Enter the description of video"}
                  {...register("summery_en", {
                    required: false,
                  })}
                  fullWidth
                  multiline
                  rows={3}
                />
              </ListItem>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                size={"small"}
                sx={{ mb: 1, mx: 2 }}
                type={"submit"}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

const VideoBox = ({ videoItem }) => {
  const snack = React.useContext(snackContext);

  const [videoTitle, setVideoTitle] = React.useState(videoItem?.title_en);
  const [videoSummary, setVideoSummary] = React.useState(videoItem?.summery_en);

  const { mutateAsync: mutateDelVideo, isLoading: delLoading } = useDelVideo();

  const handleDel = async (id) => {
    const res = await responseHandler(() => mutateDelVideo(id));
    if (res.status) {
      snack.createSnack(res.msg);
    } else {
      snack.createSnack(res.msg, "error");
    }
  };

  const { mutateAsync: updateVideo } = useUpdateVideo();

  const updateVideoState = async (id, data) => {
    const res = await responseHandler(() => updateVideo({ id, data }));
    if (res.status) snack.createSnack(res.msg);
    else snack.createSnack(res.msg, "error");
  };

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url?.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  }

  return (
    <>
      <Container sx={{ my: 1 }}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            p: 1,
            pb: 0.5,
            mb: 1,
            "&:last-child": { mb: 0 },
            border: "1px solid #ddd",
          }}
        >
          <Grid
            container
            rowGap={1}
            columnGap={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={6} sm={3}>
              <Stack direction={"column"}>
                <YoutubeEmbed embedId={youtube_parser(videoItem?.url)} />
                <Typography variant="caption">
                  {" "}
                  {moment(videoItem?.createdAt).format("lll")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={8.5}>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: 0.5,
                }}
              >
                Name
                <InputBase
                  value={videoTitle}
                  sx={tableOptionsStyle}
                  fullWidth
                  onChange={(e) => {
                    setVideoTitle(e.target.value);
                  }}
                />
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: 0.5,
                }}
              >
                Description
                <InputBase
                  value={videoSummary}
                  sx={tableOptionsStyle}
                  placeholder={"Enter Description of the video"}
                  multiline
                  fullWidth
                  onChange={(e) => {
                    setVideoSummary(e.target.value);
                  }}
                />
              </ListItem>
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                handleDel(videoItem?.id, {});
              }}
              color={"error"}
            >
              <MdOutlineDeleteOutline />
            </IconButton>
            {(!!videoTitle !== !!videoItem.title_en ||
              !!videoSummary !== !!videoItem.summery_en ||
              videoTitle !== videoItem.title_en ||
              videoSummary !== videoItem.summery_en) && (
              <IconButton
                size="small"
                onClick={() => {
                  updateVideoState(videoItem?.id, {});
                }}
              >
                <GrDocumentUpdate />
              </IconButton>
            )}
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

const YoutubeEmbed = ({ embedId }) => (
  <Avatar
    variant="square"
    src={`https://i1.ytimg.com/vi/${embedId}/maxresdefault.jpg`}
    sx={{
      height: "100px",
      width: "150px",
    }}
  />
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Youtube;
