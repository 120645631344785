import {
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { IoMdEye } from "react-icons/io";
import { MdProductionQuantityLimits } from "react-icons/md";
import { TbTruckReturn } from "react-icons/tb";
import DataTable from "../../components/DataTable";
import {
  useCancelOrder,
  useHoldOrder,
  useCompleteOrder,
  useConfirmOrder,
  useGetAllOrder,
  useUpdateOrder,
} from "../../query/order";
import tableOptionsStyle from "../../style/tableOptions";
import snackContext from "../../context/snackProvider";
import { responseHandler } from "../../utilities/response-handler";
import invoiceContext from "../../context/invoiceProvider";
import orderContext from "../../context/orderProvider";
import CartsDialog from "../../components/CartsDialog";
import StateViewer from "../../components/StateViewer";
import format from "date-fns/format";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

/**
 * get-all-pending
 * get-all-confirm
 * get-all-deliver
 * get-all-cancel
 * get-all-order
 */
const Index = () => {
  const invoice = React.useContext(invoiceContext);
  const order = React.useContext(orderContext);
  const snack = React.useContext(snackContext);
  const [params, setParams] = React.useState({
    method: "all",
    limit: 10,
    page: 1,
    filters: [],
  });

  const { data, isLoading } = useGetAllOrder(params);
  // console.log(data);

  // useMutations
  const { mutateAsync: mutateHoldOrder } = useHoldOrder();
  const { mutateAsync: mutateConfirmOrder } = useConfirmOrder();
  const { mutateAsync: mutateCompleteOrder } = useCompleteOrder();
  const { mutateAsync: mutateCancelOrder } = useCancelOrder();
  const { mutateAsync: updateOrder } = useUpdateOrder();

  const handleUpdate = async (data) => {
    const res = await responseHandler(
      () =>
        updateOrder({ orderID: data.id, data: { [data.field]: data.value } }),
      [200]
    );
    if (res.status) {
      snack.createSnack(res.data.message);
    } else {
      snack.createSnack(res.data.message, "error");
    }
  };

  const [customDateRange, setCustomDateRange] = React.useState(null);

  const handleCustomDateRangeSelect = (range) => {
    setCustomDateRange(range);
    setParams({
      ...params,

      method: `filters[]=status=start_date=${format(
        range.startDate,
        "yyyy-MM-dd"
      )}&end_date=${format(range.endDate, "yyyy-MM-dd")}&`,
    });
  };

  const cols = [
    {
      headerName: "Name",
      field: "receiver_name",
      width: 200,
      sortable: false,
      editable: true,
    },
    {
      headerName: "Phone",
      field: "receiver_number",
      width: 120,
      sortable: false,
      editable: true,
    },
    {
      headerName: "Address",
      field: "receiver_address",
      width: 250,
      editable: true,
      sortable: false,
    },
    {
      headerName: "Products",
      field: "products",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <Tooltip title={"Show Products"}>
            <CartsDialogButton
              carts={d.row.carts}
              orderId={d.row.id}
              userId={d.row.user_id}
            />
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      headerName: "Date",
      field: "created_at",
      width: 170,
      headerAlign: "center",
      renderCell: (d) => {
        return <p>{moment(d.row.created_at).format("DD/MM/YYYY hh:mm a")}</p>;
      },
      sortable: false,
    },
    {
      headerName: "Status",
      field: "status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (d) => {
        var color;
        var text;
        switch (d.row.status) {
          case "in_review":
            color = "secondary";
            text = "In Review";
            break;
          case "cancel":
            color = "error";
            break;
          case "delivered":
            color = "success";
            break;
          case "partial_delivered":
            color = "success";
            break;
          default:
            color = "default";
            break;
        }
        return (
          <Chip
            label={text || d.row.status}
            color={color}
            size={"small"}
            sx={{
              textTransform: "uppercase",
            }}
          />
        );
      },
      sortable: false,
    },
    {
      headerName: "Track Id",
      field: "tracking_code",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (d) => {
        return (
          <IconButton
            size={"small"}
            color={"black"}
            onClick={() => {
              window.open(`https://steadfast.com.bd/t/${d.row.tracking_code}`);
            }}
            disabled={!d.row.tracking_code}
          >
            <TbTruckReturn />
          </IconButton>
        );
      },
    },
    {
      headerName: "Method",
      field: "payment_method",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "BI Price",
      field: "sub_total",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "PND Fee",
      field: "pnd_total_fee",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Delivery Fee",
      field: "shipping_total_cost",
      align: "center",
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      headerName: "Reseller Price",
      field: "reseller_total_income",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Reseller Profit",
      field: "reseller_profit",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Payment Type",
      field: "payment_type",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        { value: "PAID", label: "Paid" },
        { value: "NOT_PAID", label: "Not Paid" },
        { value: "PARTIAL_PAID", label: "Partially Paid" },
      ],
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find(
          ({ value: optionValue }) => value === optionValue
        );

        return option.label;
      },
    },
    {
      headerName: "Paid Amount",
      field: "paid_amount",
      align: "center",
      width: 120,
      headerAlign: "center",
      sortable: false,
      editable: true,
      renderCell: (d) => JSON.parse(d.row.other_details)["paid_amount"],
    },
    {
      headerName: "Total Price",
      field: "total_amount",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "",
      field: "status_update",
      width: 150,
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <Select
            size={"small"}
            value={d.row.status}
            disabled={
              d.row.status === "payment_processed" || d.row.status === "cancel"
            }
            onChange={async (e) => {
              if (e.target.value === "hold") {
                const res = await responseHandler(
                  () => mutateHoldOrder(d.row.id),
                  [200]
                );
                if (res.status) {
                  snack.createSnack(res.msg);
                } else {
                  snack.createSnack(res.msg, "error");
                }
              } else if (e.target.value === "in progress") {
                const res = await responseHandler(
                  () => mutateConfirmOrder(d.row.id),
                  [200]
                );
                if (res.status) {
                  snack.createSnack(res.msg);
                } else {
                  snack.createSnack(res.msg, "error");
                }
              } else if (e.target.value === "payment_processed") {
                const res = await responseHandler(
                  () => mutateCompleteOrder(d.row.id),
                  [200]
                );
                if (res.status) {
                  snack.createSnack(res.msg);
                } else {
                  snack.createSnack(res.msg, "error");
                }
              } else if (e.target.value === "cancel") {
                const res = await responseHandler(
                  () => mutateCancelOrder(d.row.id),
                  [200]
                );
                if (res.status) {
                  snack.createSnack(res.msg);
                } else {
                  snack.createSnack(res.msg, "error");
                }
              }
            }}
            fullWidth
          >
            <MenuItem value={"in_review"} disabled>
              In Review
            </MenuItem>
            <MenuItem value={"partial_delivered"} disabled>
              Partial Delivered
            </MenuItem>
            <MenuItem value={"delivered"} disabled>
              Delivered
            </MenuItem>
            <MenuItem
              value={"payment_processed"}
              disabled={d.row.status === "payment_processed"}
            >
              Payment Processed
            </MenuItem>
            <MenuItem value={"cancel"} disabled={d.row.status === "cancel"}>
              Cancel
            </MenuItem>
          </Select>
        );
      },
      sortable: false,
    },
    {
      headerName: "Invoice",
      field: "invoice_print",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <Tooltip title={"Show Invoice"}>
            <IconButton
              size={"small"}
              color={"black"}
              onClick={() => {
                // console.log(d.row);
                // window.open(
                //   "https://admin.pndservicebd.com/orderdetails.html?id=" +
                //     d.row.id
                // );
                invoice.showInvoice(d.row.id);
              }}
            >
              <IoMdEye />
            </IconButton>
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      headerName: "Action",
      field: "return_button",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <Tooltip title={"Return Order"}>
            <span>
              <IconButton
                size={"small"}
                color={"black"}
                onClick={() => {
                  order.showOrder(d.row.id);
                }}
                disabled={
                  d.row.status !== "in progress" && d.row.status !== "delivered"
                }
              >
                <TbTruckReturn />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <StateViewer
        stateList={[
          {
            num: data?.data?.value?.in_progress_order,
            title: "In Progress Order",
            price: data?.data?.value?.in_progress_payment,
            title2: "Payment",
          },
          {
            num: data?.data?.value?.due_order,
            title: "Due Order",
            price: data?.data?.value?.due_payment,
            title2: "Payment",
          },
          
          {
            num: data?.data?.value?.paid_order,
            title: "Paid Order",
            price: data?.data?.value?.paid_payment,
            title2: "Payment",
          },
        ]}
      />
      <Paper
        elevation={0}
        sx={{
          p: 2,
          border: "1px solid #ccc",
          my: 2,
        }}
      >
        <Grid
          container
          rowGap={1}
          columnGap={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={8.7}>
            <InputBase
              placeholder="Search Order"
              sx={tableOptionsStyle}
              onChange={(e) => {
                setParams({
                  ...params,
                  filters: [
                    // `receiver_name~${e.target.value}`,
                    `receiver_number~${e.target.value}`,
                    // `receiver_address~${e.target.value}`,
                  ],
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              // disabled={params.method === "delivered"}
              fullWidth
            >
              <MenuItem
                value={"filters[]=status=in_review&"}
                disabled={params.method === "filters[]=status=in_review&"} // delivered
              >
                Partial Delivered
              </MenuItem>
              <MenuItem
                value={"filters[]=status=partial_delivered&"}
                disabled={
                  params.method === "filters[]=status=partial_delivered&"
                } // delivered
              >
                Partial Delivered
              </MenuItem>
              <MenuItem
                value={"filters[]=status=delivered&"}
                disabled={params.method === "filters[]=status=delivered&"} // delivered
              >
                Delivered
              </MenuItem>
            </Select>
          </Grid>
          {/* status in-progress, due, paid */}
          <Grid item xs={12} sm={3}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.method === "all"}>
                Payment Status
              </MenuItem>
              <MenuItem
                value={"filters[]=status=in_progress&"}
                disabled={params.method === "filters[]=status=in_progress&"} // when order status=pending
              >
                In-Progress
              </MenuItem>
              <MenuItem
                value={"filters[]=status=delivered&"}
                disabled={params.method === "filters[]=status=delivered&"} //when order delivered
              >
                Due
              </MenuItem>
              <MenuItem
                value={"filters[]=status=payment_processed&"}
                disabled={
                  params.method === "filters[]=status=payment_processed&"
                } //when status=payment processed
              >
                Paid
              </MenuItem>
            </Select>
          </Grid>

          {/* search by date */}
          <Grid item xs={12} sm={4}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              // disabled={params.method === "delivered"}
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.method === "all"}>
                Search by date
              </MenuItem>
              <MenuItem value={"filters[]=status=today&"}>Today</MenuItem>
              <MenuItem value={"filters[]=status=yesterday&"}>Yesterday</MenuItem>
              <MenuItem value={"filters[]=status=last_7_days&"}>
                Last 7 days
              </MenuItem>
              <MenuItem value={"filters[]=status=last_30_days&"}>
                Last 30 days
              </MenuItem>
              <MenuItem value={"filters[]=status=this_year&"}>
                This Year
              </MenuItem>
              <MenuItem value={"filters[]=status=life_time&"}>Life Time</MenuItem>
              <MenuItem value={"custom_date_range"}>Custom</MenuItem>
            </Select>
            {params.method === "custom_date_range" && (
              <CustomDateRangePicker
                onDateRangeSelect={handleCustomDateRangeSelect}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <DataTable
        columns={cols}
        rows={Array.from(data?.data?.value?.data?.data || [], (d) => ({
          ...d,
          payment_type: JSON.parse(d.other_details)["payment_type"],
        }))}
        onCellEditCommit={handleUpdate}
        experimentalFeatures={{ newEditngApi: true }}
        isLoading={isLoading}
        paginationMode={"server"}
        rowCount={data?.data?.value?.data?.total || 0}
        page={(params?.page || 1) - 1}
        onPageChange={(newPage) =>
          setParams({
            ...params,
            page: newPage + 1,
          })
        }
        pageSize={params?.limit}
        onPageSizeChange={(pageSize) =>
          setParams({
            ...params,
            limit: pageSize,
            page: 1,
          })
        }
      />
    </Container>
  );
};

export const CartsDialogButton = ({ carts, orderId, userId }) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(!open);
  return (
    <>
      <IconButton
        size={"small"}
        color={"black"}
        disabled={!carts?.length}
        onClick={onClose}
      >
        <MdProductionQuantityLimits />
      </IconButton>
      {open && (
        <CartsDialog
          open={open}
          onClose={onClose}
          carts={carts || []}
          orderId={orderId}
          userId={userId}
        />
      )}
    </>
  );
};

export default Index;
