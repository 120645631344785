import React from "react";
import {
  Chip,
  Container,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Button,
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import { useGetAllProduct } from "../../query/product";
import { useGetAllCategory } from "../../query/category";
import ButtonSwitch from "../../components/ButtonSwitch";
import moment from "moment";
import tableOptionsStyle from "../../style/tableOptions";

import { MdAdd } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { rootURL } from "../../service/instance";

import StateViewer from "../../components/StateViewer";
import UpdateProductDrawer from "./UpdateProductDrawer";
import CreateProductDrawer from "./CreateProductDrawer";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import format from "date-fns/format";

const Index = () => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(!open);
  const [selectedCategory, setSelectedCategory] = React.useState("null");

  const [params, setParams] = React.useState({
    method: "all",
    limit: 10,
    page: 1,
    filters: [],
  });

  const { data: catData, isLoading: isCatLoading } = useGetAllCategory();
  const { data, isLoading } = useGetAllProduct(params);

  // console.log(data);

  const [customDateRange, setCustomDateRange] = React.useState(null);

  const handleCustomDateRangeSelect = (range) => {
    setCustomDateRange(range);
    setParams({
      ...params,

      method: `filters[]=status=start_date=${format(
        range.startDate,
        "yyyy-MM-dd"
      )}&end_date=${format(range.endDate, "yyyy-MM-dd")}&`,
    });
  };

  const cols = [
    {
      headerName: "#",
      field: "show_info",
      width: 60,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <UpdateProductDrawer info={params.row} drawerIcon={<IoMdEye />} />
        </>
      ),
    },
    {
      headerName: "Image",
      headerAlign: "center",
      field: "receiver_number",
      align: "center",
      width: 80,
      renderCell: (params) => (
        <Avatar src={rootURL + params.row.photo} variant="square" />
      ),
    },
    {
      headerName: "Product Name",
      headerAlign: "center",
      field: "title_en",
      align: "center",
      width: 200,
    },

    // {
    //   headerName: "Color",
    //   headerAlign: "center",
    //   field: "colors",
    //   width: 80,
    //   align: "center",
    //   renderCell: () => (
    //     <>
    //       <IconButton size={"small"}>
    //         <IoMdEye />
    //       </IconButton>
    //     </>
    //   ),
    // },
    // {
    //   headerName: "Category",
    //   headerAlign: "center",
    //   field: "category_id",
    //   width: 200,
    //   align: "center",
    //   renderCell: (params) => (
    //     <>
    //       {catData?.data?.value?.map((cat) => {
    //         return cat.id === params.row.category_id ? cat.title_en : "-";
    //       })[0] || "-"}
    //     </>
    //   ),
    // },
    {
      headerName: "Buy Price",
      headerAlign: "center",
      field: "buy_price",
      align: "center",
      width: 100,
    },
    {
      headerName: "Sell Price",
      headerAlign: "center",
      field: "sell_price",
      width: 100,
      align: "center",
    },
    {
      headerName: "Stock",
      headerAlign: "center",
      field: "quantity",
      align: "center",
    },
    {
      headerName: "Total Amount",
      headerAlign: "center",
      field: "total_amount",
      align: "center",
      renderCell: (d) => d.row.quantity * d.row.sell_price,
      width: 120,
    },
    {
      headerName: "Status",
      headerAlign: "center",
      field: "status_stock",
      align: "center",
      width: 180,
      renderCell: (d) => (
        <Chip
          label={d.row.quantity > 0 ? "In Sell" : "Stock Out"}
          color={d.row.quantity > 0 ? "success" : "error"}
          size={"small"}
          sx={{
            textTransform: "uppercase",
          }}
        />
      ),
    },
    {
      headerName: "Discount",
      headerAlign: "center",
      field: "discount",
      align: "center",
    },
    {
      headerName: "Published",
      headerAlign: "center",
      field: "status",
      align: "center",
      width: 120,
      renderCell: (params) => (
        <ButtonSwitch
          checked={params.row.status === "active"}
          color={"success"}
        />
      ),
    },
    {
      headerName: "Action",
      headerAlign: "center",
      field: "actions",
      align: "center",
      width: 120,
      renderCell: (params) => (
        <>
          <UpdateProductDrawer info={params.row} />
        </>
      ),
    },
  ];

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <StateViewer
        stateList={[
          {
            num: data?.data?.value?.total_product,
            title: "Total Product",
          },
          {
            num: data?.data?.value?.total_publish_product,
            title: "Stock-in",
          },
          {
            num: data?.data?.value?.stock_in_price,
            title: "Stock-in Product Amount",
          },
          {
            num: data?.data?.value?.total_unpublish_product,
            title: "Stock-out",
          },
        ]}
      />
      <Paper
        elevation={0}
        sx={{
          p: 2,
          border: "1px solid #ccc",
          my: 2,
        }}
      >
        <Grid
          container
          rowGap={1}
          columnGap={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} md={5.7}>
            <InputBase
              placeholder="Search Product"
              sx={tableOptionsStyle}
              onChange={(e) => {
                setParams({
                  ...params,
                  filters: [`title_en~${e.target.value}`],
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5.9} md={3}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setParams({
                  ...params,
                  filters: [`category_id=${e.target.value}`],
                });
              }}
              fullWidth
            >
              <MenuItem value={"null"} disabled>
                Select Category
              </MenuItem>
              {catData?.data?.value?.map((cat) => (
                <MenuItem
                  key={cat.id}
                  value={cat.id}
                  disabled={cat.id === selectedCategory}
                >
                  {cat.title_en}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* search stock quantity and price */}
          <Grid item xs={12} sm={3}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              // disabled={params.method === "delivered"}
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.method === "all"}>Top stock or sell product</MenuItem>
              <MenuItem value={"filters[]=status=stock_by_quantity&"}>
                Stock by Quantity
              </MenuItem>
              <MenuItem value={"filters[]=status=stock_by_amount&"}>
                Stock by Amount
              </MenuItem>
              <MenuItem value={"filters[]=status=sell_by_quantity&"}>
                Sell by Quantity
              </MenuItem>
              <MenuItem value={"filters[]=status=sell_by_amount&"}>
                Sell by Amount
              </MenuItem>
            </Select>
          </Grid>
          {/* search by date */}
          <Grid item xs={12} sm={4}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              // disabled={params.method === "delivered"}
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.method === "all"}>
                Search by date
              </MenuItem>
              <MenuItem value={"filters[]=status=today&"}>Today</MenuItem>
              <MenuItem value={"filters[]=status=yesterday&"}>Yesterday</MenuItem>
              <MenuItem value={"filters[]=status=last_7_days&"}>
                Last 7 days
              </MenuItem>
              <MenuItem value={"filters[]=status=last_30_days&"}>
                Last 30 days
              </MenuItem>
              <MenuItem value={"filters[]=status=this_year&"}>
                This Year
              </MenuItem>
              <MenuItem value={"filters[]=status=life_time&"}>Life Time</MenuItem>
              <MenuItem value={"custom_date_range"}>Custom</MenuItem>
            </Select>
            {params.method === "custom_date_range" && (
              <CustomDateRangePicker
                onDateRangeSelect={handleCustomDateRangeSelect}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={5.9} md={3}>
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              sx={{
                height: "52px",
              }}
              onClick={onClose}
              startIcon={<MdAdd />}
              fullWidth
            >
              Add Product
            </Button>
            <CreateProductDrawer open={open} onClose={onClose} />
          </Grid>
        </Grid>
      </Paper>

      <DataTable
        columns={cols}
        rows={data?.data?.value?.data?.data || []}
        isLoading={isLoading}
        paginationMode={"server"}
        rowCount={data?.data?.value?.data?.total || 0}
        page={(params?.page || 1) - 1}
        onPageChange={(newPage) =>
          setParams({
            ...params,
            page: newPage + 1,
          })
        }
        pageSize={params?.limit}
        onPageSizeChange={(pageSize) =>
          setParams({
            ...params,
            limit: pageSize,
          })
        }
      />
    </Container>
  );
};

export default Index;
