import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

//get all order
const getAllOrder = (params) => {
  let queryString = `order/get-all-order?${
    params.method !== "all" ? params.method : ""
  }orders=created_at-DESC&limit=${params.limit}&page=${params.page}`; // /${params.limit}?page=${params.page}
  params.filters?.map((filter) => {
    queryString += `&filters[]=${filter}`;
  });
  return instance.get(queryString);
};

export const useGetAllOrder = (params) => {
  return useQuery(["get-all-order", params], () => getAllOrder(params), {
    // refetchInterval: 20000,
  });
};
//get order list by user
const getOrderListByUser = (userId, params) => {
  let queryString = `order/get-all-${params.method}-user/${userId}?orders=created_at-DESC&limit=${params.limit}&page=${params.page}`;
  params.filters?.map((filter) => {
    queryString += `&filters[]=${filter}`;
  });
  return instance.get(queryString);
};

export const useGetOrderListByUser = (userId, params) => {
  return useQuery(
    ["get-order-by-user", userId, params],
    () => getOrderListByUser(userId, params),
    {
      enabled: !!userId,
      retry: 0,
    }
  );
};

// confirm order
const holdOrder = (id) => {
  return instance.put(`order/${id}/hold`);
};

export const useHoldOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(holdOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
      queryClient.invalidateQueries("get-order-by-user");
    },
  });
};

// confirm order
const confirmOrder = (id) => {
  return instance.put(`order/confirm-order/${id}`);
};

export const useConfirmOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(confirmOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
      queryClient.invalidateQueries("get-order-by-user");
    },
  });
};

// complete order
const completeOrder = (id) => {
  return instance.put(`order/complete-order/${id}`);
};

export const useCompleteOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(completeOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
      queryClient.invalidateQueries("get-order-by-user");
    },
  });
};

// cancel order
const cancelOrder = (id) => {
  return instance.put(`order/cancel-order/${id}`);
};

export const useCancelOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(cancelOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
      queryClient.invalidateQueries("get-order-by-user");
    },
  });
};

// complete order
const returnOrder = (data) => {
  return instance.post(`order/return`, data);
};

export const useReturnOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(returnOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
      queryClient.invalidateQueries("get-order-by-user");
    },
  });
};

//update order
const updateOrder = ({ orderID, data }) => {
  return instance.put(`order/${orderID}`, data);
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
    },
  });
};

// delete cart
const deleteCart = ({ orderId, cartId }) => {
  instance.delete(`/order/${orderId}/cart/${cartId}`);
};

export const useDeleteCart = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-orders");
    },
  });
};

// update cart
const updateCart = ({ orderId, cartId, data }) =>
  instance.put(`/order/${orderId}/cart/${cartId}`, data);

export const useUpdateCart = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
    },
  });
};

// add cart
const addCart = ({ orderId, data }) =>
  instance.post(`/order/${orderId}/cart/`, data);

export const useAddCart = () => {
  const queryClient = useQueryClient();
  return useMutation(addCart, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-order");
    },
  });
};
