import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import {
  MdClose,
  MdDelete,
  MdEdit,
  MdEditOff,
  MdOutlineAddShoppingCart,
  MdOutlineUpload,
} from "react-icons/md";
import { getAttachment } from "../service/instance";
import { useToggle } from "@tam11a/react-use-hooks";
import CInput from "../pages/Login/CInput";
import { useAddCart, useDeleteCart, useUpdateCart } from "../query/order";
import snackContext from "../context/snackProvider";
import { responseHandler } from "../utilities/response-handler";
import {
  useAddBiCart,
  useDeleteBiCart,
  useUpdateBiCart,
} from "../query/bi-order";
import { useGetAllProduct } from "../query/product";

const CartsDialog = ({ carts, open, onClose, isBi, orderId, userId }) => {
  const { state, toggleState } = useToggle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "95vw",
          maxWidth: "960px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant={"button"}>Products</Typography>
        <IconButton size={"small"} onClick={onClose}>
          <MdClose />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          p: 1,
        }}
      >
        {carts?.map?.((cart) => (
          <Cart key={cart.id} cart={cart} isBi={isBi} />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant={"contained"} color="black" onClick={toggleState}>
          Add New
        </Button>
        {state && (
          <AddNewProduct
            orderId={orderId}
            userId={userId}
            onClose={toggleState}
            isBi={isBi}
          />
        )}

        <Button variant={"outlined"} color="black" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddNewProduct = ({ orderId, userId, isBi, onClose }) => {
  const [search, setSearch] = React.useState("");
  const [colorDetails, setColorDetails] = React.useState();
  const { data } = useGetAllProduct(
    search
      ? {
          limit: 100,
          page: 1,
          filters: [`title_en~${search}`],
        }
      : undefined
  );
  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "95vw",
          maxWidth: "600px",
        },
      }}
    >
      <CInput
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search Product to Add"
        endAdornment={
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        }
      />
      <DialogContent>
        {colorDetails ? (
          <>
            {Object.keys(colorDetails?.colors)?.map((c) => (
              <ListItem key={c}>
                <NewProductVariant
                  orderId={orderId}
                  userId={userId}
                  isBi={isBi}
                  onClose={onClose}
                  name={c}
                  productId={colorDetails.productId}
                  qt={colorDetails?.colors[c]}
                  sell_price={colorDetails.sell_price}
                />
              </ListItem>
            ))}
          </>
        ) : (
          <>
            {!search && (
              <Box
                sx={{
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>Search Product</Typography>
              </Box>
            )}
            {data?.data?.value?.data?.data?.map((product) => (
              <ListItem key={product.id}>
                <Avatar
                  src={getAttachment(product?.photo)}
                  alt={product?.title_en}
                  variant={"rounded"}
                  sx={{
                    mr: 3,
                    height: "40px",
                    width: "40px",
                  }}
                />
                <ListItemText
                  primary={product.title_en}
                  secondary={`${product.sell_price} tk`}
                />
                <Button
                  size={"small"}
                  disabled={!product.quantity}
                  onClick={() =>
                    setColorDetails({
                      productId: product.id,
                      colors: JSON.parse(product.color_product_details)?.color,
                      sell_price: product.sell_price,
                    })
                  }
                >
                  Add to Cart
                </Button>
              </ListItem>
            ))}
          </>
        )}
      </DialogContent>
      {colorDetails && (
        <DialogActions onClick={() => setColorDetails()}>
          <Button>{"<<"} Go Back</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const NewProductVariant = ({
  orderId,
  userId,
  isBi,
  onClose,
  name,
  qt,
  productId,
  sell_price,
}) => {
  console.log(isBi);
  const snack = React.useContext(snackContext);
  const inputRef = React.useRef();
  const priceRef = React.useRef();
  const { mutateAsync: updateOrder } = useAddCart();
  const { mutateAsync: biOrder } = useAddBiCart();
  const handleUpdate = async () => {
    const res = await responseHandler(
      () =>
        isBi
          ? biOrder({
              orderId: orderId,
              data: {
                cart_info: {
                  color: {
                    [name]: inputRef.current.value,
                  },
                },
                product_id: productId,
                user_id: userId,
                price: priceRef.current.value,
                quantity: inputRef.current.value,
              },
            })
          : updateOrder({
              orderId: orderId,
              data: {
                cart_info: {
                  color: {
                    [name]: inputRef.current.value,
                  },
                },
                product_id: productId,
                user_id: userId,
                price: priceRef.current.value,
                quantity: inputRef.current.value,
              },
            }),
      [200]
    );
    if (res.status) {
      snack.createSnack(res.data.message || "Order updated successfully!");
      onClose();
    } else {
      snack.createSnack(res.data.message, "error");
    }
  };
  return (
    <>
      <ListItemText primary={name} secondary={`${qt} items`} />
      <CInput
        placeholder="price"
        inputRef={priceRef}
        disabled={!qt}
        defaultValue={sell_price}
        endAdornment={<>tk</>}
        sx={{ width: "80px", mr: 1 }}
      />
      <CInput
        placeholder="qt."
        inputRef={inputRef}
        disabled={!qt}
        sx={{ width: "120px" }}
        endAdornment={
          <IconButton
            size={"small"}
            onClick={() => handleUpdate()}
            disabled={!qt}
          >
            <MdOutlineAddShoppingCart />
          </IconButton>
        }
      />
    </>
  );
};

const Cart = ({ cart, isBi }) => {
  let colors = JSON.parse(cart.cart_info)?.color;

  const snack = React.useContext(snackContext);
  const { state, toggleState } = useToggle(false);
  const { mutateAsync: updateCart } = useUpdateCart();
  const { mutateAsync: updateBiCart } = useUpdateBiCart();
  const { mutateAsync: deleteCart } = useDeleteCart();
  const { mutateAsync: deleteBiCart } = useDeleteBiCart();

  const qtRef = React.useRef();
  const priceRef = React.useRef();
  const sellPriceRef = React.useRef();

  const handleUpdate = async () => {
    const res = await responseHandler(
      () =>
        isBi
          ? updateBiCart({
              orderId: cart.bi_order_id,
              cartId: cart.id,
              data: {
                quantity: qtRef.current.value,
                price: priceRef.current.value,
              },
            })
          : updateCart({
              orderId: cart.order_id,
              cartId: cart.id,
              data: {
                quantity: qtRef.current.value,
                price: priceRef.current.value,
                sell_price: sellPriceRef.current.value,
              },
            }),
      [200]
    );
    // console.log("updated ", res)
    if (res.status) {
      snack.createSnack(res.data.msg || "Order updated successfully!");
      toggleState();
    } else {
      snack.createSnack(res.data.msg, "error");
    }
  };

  const handleDelete = async () => {
	// console.log("orderId : ", cart.order_id);
    const res = await responseHandler(
      () =>
        isBi
          ? deleteBiCart({
              orderId: cart.bi_order_id,
              cartId: cart.id,
            })
          : deleteCart({
              orderId: cart.order_id,
              cartId: cart.id,
            }),
      [200]
    );
    // console.log("deleted ", res);
    if (res.status) {
      snack.createSnack(res.data.msg || "Order deleted successfully!");
    } else {
      snack.createSnack(res.data, "error");
    }
  };

  return (
    <ListItem alignItems="flex-start">
      <Avatar
        src={getAttachment(cart.product?.photo)}
        alt={cart.product?.title_en}
        variant={"rounded"}
        sx={{
          mr: 3,
          height: "60px",
          width: "60px",
        }}
        // component={`a`}
        // target={"_blank"}
        // href={`https://pndservicebd.com?product=${cart.product_id}`}
      />
      <ListItemText
        primary={
          <>
            {cart.product?.title_en}{" "}
            <IconButton onClick={toggleState} size={"small"}>
              {!state ? <MdEdit /> : <MdEditOff />}
            </IconButton>
            {state && (
              <IconButton
                size={"small"}
                color={"primary"}
                onClick={handleUpdate}
              >
                <MdOutlineUpload />
              </IconButton>
            )}
            <IconButton size={"small"} color={"primary"} onClick={handleDelete}>
              <MdDelete />
            </IconButton>
          </>
        }
        secondary={
          <>
            {state ? (
              <Stack direction={"row"} columnGap={1}>
                <Stack direction={"column"}>
                  <Typography variant="caption">Quantity</Typography>
                  <CInput
                    size="small"
                    defaultValue={colors?.[Object.keys(colors)[0]]}
                    inputRef={qtRef}
                  />
                </Stack>
                <Stack direction={"column"}>
                  <Typography variant="caption">Price</Typography>
                  <CInput
                    size="small"
                    defaultValue={cart.price}
                    inputRef={priceRef}
                  />
                </Stack>
                {!isBi && (
                  <Stack direction={"column"}>
                    <Typography variant="caption">Sell Price</Typography>
                    <CInput
                      size="small"
                      defaultValue={cart.sell_price}
                      inputRef={sellPriceRef}
                    />
                  </Stack>
                )}
              </Stack>
            ) : (
              <>
                <div>{`${Object.keys(colors)?.map(
                  (color, index) =>
                    `${index ? ", " : ""}${color} (${colors[color]} Items)`
                )} × ${cart.sell_price || cart.price || 0} ৳`}</div>
                <b>{`${cart.price || 0} ৳`}</b>{" "}
              </>
            )}
          </>
        }
      />
      <Typography
        variant={"button"}
        noWrap={true}
        sx={{
          minWidth: "40px",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        {cart.total_amount_with_sell_price || cart.total_amount || 0} ৳
      </Typography>
    </ListItem>
  );
};

export default CartsDialog;
