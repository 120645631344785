import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

const getImage = () => {
	return instance.get(`/home/img/get-home-img`);
};

export const useGetImage = () => {
	return useQuery(["home-images"], getImage, {
		select: (data) => data?.data?.value?.[0] || undefined,
	});
};

const updateImage = ({ file, name }) => {
	return instance.postForm(`/home/img/update`, { [name]: file });
};

export const useUpdateImage = () => {
	const queryClient = useQueryClient();
	return useMutation(updateImage, {
		onSuccess: () => queryClient.invalidateQueries("home-images"),
	});
};
