import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

const getNewsTicker = () => {
  return instance.get(`news-ticker`, {});
};

export const useGetNewsTicker = () => {
  return useQuery("get-news-ticker", () => getNewsTicker(), {});
};

const updateNews = ({ id, data }) => {
  return instance.put(`news-ticker/${id}`, data);
};

export const useUpdateNews = () => {
  const queryClient = useQueryClient();
  return useMutation(updateNews, {
    onSuccess: () => queryClient.invalidateQueries("get-news-ticker"),
  });
};

const createNews = (data) => {
  return instance.post(`news-ticker`, data);
};

export const useCreateNews = () => {
  const queryClient = useQueryClient();
  return useMutation(createNews, {
    onSuccess: () => queryClient.invalidateQueries("get-news-ticker"),
  });
};

const delTicker = (id) => {
  return instance.delete(`news-ticker/${id}`);
};

export const useDelTicker = () => {
  const queryClient = useQueryClient();
  return useMutation(delTicker, {
    onSuccess: () => queryClient.invalidateQueries("get-news-ticker"),
  });
};
