import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { MdClose } from "react-icons/md";
import orderContext from ".";
import DataTable from "../../components/DataTable";
import { useReturnOrder } from "../../query/order";
import { rootURL } from "../../service/instance";
import tableOptionsStyle from "../../style/tableOptions";
import { responseHandler } from "../../utilities/response-handler";
import snackContext from "../snackProvider";

const OrderDialog = ({ open, onClose }) => {
  const snack = React.useContext(snackContext);
  const order = React.useContext(orderContext);
  const [rows, setRows] = React.useState([]);
  const [selections, setSelections] = React.useState([]);
  const [delFee, setDelFee] = React.useState();

  const { mutateAsync: returnOrder, isLoading } = useReturnOrder();

  React.useEffect(() => setDelFee(), [open]);
  React.useEffect(() => setRows(order.info?.carts || []), [order.info]);

  const returnOrderFunc = async () => {
    if (delFee === undefined) {
      snack.createSnack("Add Delivery Fee", "error");
      return;
    }
    const res = await responseHandler(() =>
      returnOrder({
        shipping_total_cost: parseFloat(delFee),
        cart_info: selections,
        order_id: order.info?.id,
        return_all: selections.length === rows.length,
      })
    );
    if (res.status) {
      snack.createSnack(res.msg);
      onClose();
    } else {
      snack.createSnack(res.msg, "error");
    }
  };

  const columns = [
    {
      headerName: "Cart Descriptions",
      field: "item_description",
      minWidth: 350,
      flex: 1,
      sortable: false,
      renderCell: (d) => {
        let colors = JSON.parse(d.row.cart_info)?.color;
        return (
          <>
            <Avatar
              src={rootURL + d.row.product.photo}
              variant={"square"}
              alt={d.row.product.title_en}
              sx={{ mr: 1 }}
            />
            <b>{d.row.product.title_en}</b>
            <Typography
              sx={{
                ml: 1,
              }}
              variant={"caption"}
            >
              {Object.keys(colors)?.map(
                (color, index) =>
                  `${index ? ", " : ""}${color} - ${colors[color]}Pcs`
              )}
            </Typography>
          </>
        );
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Reseller Price",
      field: "total_amount_with_sell_price",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
  ];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        position: "absolute",
      }}
      PaperProps={{
        sx: {
          minWidth: {
            xs: "95vw",
            md: "800px",
          },
          maxWidth: { xs: "95vw", md: "1200px" },
          maxHeight: {
            xs: "97vh",
          },
          minHeight: {
            xs: "97vh",
            sm: "unset",
          },
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          columnGap={1}
        >
          <Typography>Return Order</Typography>
          <IconButton onClick={onClose} size={"small"} color={"error"}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      {isLoading ? <LinearProgress /> : <Divider />}
      <DialogContent>
        <Typography variant={"button"}>Delivery Fee *</Typography>
        <InputBase
          sx={{
            ...tableOptionsStyle,
            my: 1,
          }}
          fullWidth
          placeholder="Delivery Fee *"
          type="tel"
          value={delFee}
          onChange={(e) => setDelFee(e.target.value)}
        />
        <Typography variant={"button"}>Carts</Typography>
        <Box
          sx={{
            mb: 1,
          }}
        />
        <DataTable
          keepNonExistentRowsSelected
          showCellRightBorder
          checkboxSelection={true}
          columns={columns}
          rows={rows}
          onSelectionModelChange={(ids) => {
            setSelections(
              Array.from(ids, (id) => {
                return {
                  id,
                };
              })
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button
            color={"black"}
            variant={"contained"}
            size={"small"}
            onClick={returnOrderFunc}
            disabled={!selections?.length || isLoading}
          >
            Return
          </Button>
          <Button
            color={"error"}
            variant={"outlined"}
            size={"small"}
            onClick={onClose}
          >
            cancel
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDialog;
