import { Container } from "@mui/material";
import React from "react";

import { useParams, Link, Navigate } from "react-router-dom";

import CTab from "../../components/CTab";
import CTabs from "../../components/CTabs";
import News from "./News";
import Youtube from "./Youtube";

const Index = () => {
  const { id, path_url } = useParams();
  return (
    <>
      <Container sx={{ pt: 1 }}>
        <CTabs
          value={path_url}
          sx={{
            width: "fit-content",
            minWidth: {
              xs: "90vw",
              sm: "350px",
            },
            maxWidth: "90vw",
            mx: "auto",
            my: 2,
          }}
        >
          <CTab
            value={"news"}
            label={"News"}
            component={Link}
            to={`/additional-info/news`}
          />
          <CTab
            value={"youtube"}
            label={"Youtube Videos"}
            component={Link}
            to={`/additional-info/youtube`}
          />
        </CTabs>
        {path_url === "news" ? (
          <>
            <News />
          </>
        ) : path_url === "youtube" ? (
          <>
            <Youtube />
          </>
        ) : (
          <>
            <Navigate to={`/additional-info/news`} />
          </>
        )}
      </Container>
    </>
  );
};

export default Index;
