import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { MdClose, MdOutlinePrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { Preview, print } from "react-html2pdf";
import biInvoiceContext from ".";

import BIlogo from "../../assets/bim-order.svg";

const BiInvoiceDialog = ({ open, onClose }) => {
  const printRef = React.useRef(null);
  const BiInvoice = React.useContext(biInvoiceContext);
  const [comment, setComment] = React.useState(true);
  const toggleComment = () => setComment(!comment);

  const reactToPrintContent = React.useCallback(() => {
    return printRef.current;
  }, [printRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: BiInvoice.info?.id
      ? "BiInvoice-" + BiInvoice.info?.id
      : "BiInvoice-" + Date.now(),
    removeAfterPrint: true,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          position: "absolute",
        }}
        PaperProps={{
          sx: {
            minWidth: {
              xs: "95vw",
              md: "800px",
            },
            maxWidth: { xs: "95vw", md: "1200px" },
            maxHeight: {
              xs: "97vh",
            },
            minHeight: {
              xs: "97vh",
              sm: "unset",
            },
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            columnGap={1}
          >
            <ButtonGroup>
              <Button
                color={"info"}
                variant={"contained"}
                size={"small"}
                startIcon={<MdOutlinePrint />}
                onClick={handlePrint}
              >
                Print
              </Button>
              <Button
                color={"black"}
                variant={"contained"}
                size={"small"}
                // startIcon={<MdOutlinePrint />}
                onClick={() =>
                  print(
                    BiInvoice.info?.id
                      ? "BiInvoice-" + BiInvoice.info?.id
                      : "BiInvoice-" + Date.now(),
                    "jsx-template"
                  )
                }
              >
                Download
              </Button>
              <Button
                color={"primary"}
                variant={comment ? "contained" : "outlined"}
                size={"small"}
                // startIcon={<MdOutlinePrint />}
                onClick={() => toggleComment()}
              >
                Comment
              </Button>
            </ButtonGroup>
            <IconButton onClick={onClose} size={"small"} color={"error"}>
              <MdClose />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <Preview id={"jsx-template"}>
          <Box ref={printRef}>
            <DialogContent>
              <PrintableArea showComment={comment} />
            </DialogContent>
          </Box>
        </Preview>
      </Dialog>
    </>
  );
};

const PrintableArea = ({ showComment, ...others }) => {
  const BiInvoice = React.useContext(biInvoiceContext);
  return BiInvoice.info ? (
    <React.Fragment {...others}>
      {/* <Typography variant="button">Invoice</Typography> */}
      <Grid
        container
        sx={{
          mt: 2,
          "*": {
            fontSize: "0.85em",
          },
        }}
      >
        {/* <Grid
					item
					xs={4}
				>
					<Typography sx={{ fontWeight: "bold" }}>
						{BiInvoice.info?.company_name}
					</Typography>
					<Typography variant={"subtitle2"}>
						{BiInvoice.info?.company_number}
					</Typography>
				</Grid> */}
        <Grid item xs={8}>
          <Avatar
            src={"/bi-extended.svg"}
            variant={"square"}
            sx={{
              height: "auto",
              width: "230px",
              // mx: "auto",
              background: "transparent",
              borderColor: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: "right",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {/* {BiInvoice.info?.pnd_name} */}
            Brothers Importing Inc
          </Typography>
          <Typography variant={"subtitle2"}>
            {BiInvoice.info?.pnd_address}
          </Typography>
          <Typography variant={"subtitle2"}>
            {BiInvoice.info?.pnd_phone}
          </Typography>
          <Typography variant={"subtitle2"}>
            {/* {BiInvoice.info?.pnd_email} */}
            brothersimporting@gmail.com
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="caption">
        <b>Invoice</b>#{BiInvoice.info?.invoice_id || BiInvoice.info?.id}
      </Typography>

      <Paper
        sx={{
          mt: 1,
          mb: 2,
          bgcolor: "#E9EEEE",
          px: 2,
          py: 1.5,
          "*": {
            fontSize: "0.9em",
          },
        }}
        elevation={0}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="button"
              sx={{
                fontWeight: "bold",
              }}
            >
              bill to
            </Typography>
            <Box
              sx={{
                mt: 1,
              }}
            />
            <Typography variant={"subtitle2"} sx={{ fontWeight: "bold" }}>
              {BiInvoice.info?.receiver_name}
            </Typography>
            <Typography variant={"subtitle2"}>
              {BiInvoice.info?.receiver_address}
            </Typography>
            <Typography variant={"subtitle2"}>
              {BiInvoice.info?.receiver_number}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "right",
            }}
          >
            <Typography variant={"subtitle2"}>
              <b>Invoice Date:</b>{" "}
              {moment(BiInvoice.info?.created_at).format("ll")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer>
        <Table
          sx={{
            "& tr:last-child th": {
              border: "none",
            },
            "*": {
              fontSize: "0.85em",
            },
          }}
        >
          <TableHead
            sx={{
              bgcolor: "#E9EEEE",
            }}
          >
            <TableRow>
              <TableCell
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Item Description
              </TableCell>
              <TableCell
                align="center"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                align="center"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Price
              </TableCell>
              <TableCell
                align="right"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {BiInvoice.info?.carts?.map((cart) => {
              let colors = JSON.parse(cart.cart_info)?.color;
              return (
                <TableRow key={cart.id}>
                  <TableCell
                    padding={"none"}
                    sx={{
                      width: "60%",
                      p: 1,
                      px: 2,
                      fontSize: "1em",
                    }}
                  >
                    <b>{cart.product?.title_en}</b>
                    {/* <br /> */}
                    <Typography
                      sx={{
                        ml: 1,
                      }}
                      variant={"caption"}
                    >
                      {Object.keys(colors)?.map?.(
                        (color, index) =>
                          `${index ? ", " : ""}${color} - ${colors[color]}Pcs`
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.quantity}
                  </TableCell>
                  <TableCell
                    align="center"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.price}৳
                  </TableCell>
                  <TableCell
                    align="right"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.total_amount}৳
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!BiInvoice.info?.carts?.length && (
        <Alert
          severity="error"
          sx={{
            mt: 1,
            width: "100%",
          }}
        >
          No Carts Found!!
        </Alert>
      )}

      <Grid
        container
        sx={{
          mt: 5,
          "*": {
            fontSize: "0.8em",
          },
        }}
      >
        <Grid item xs={8}>
          {showComment && (
            <Box
              sx={{
                maxWidth: "300px",
              }}
            >
              {/* <Typography variant={"button"}>Comment:</Typography> */}

              <Typography variant={"caption"}>
                {Object.keys(
                  BiInvoice.info?.other_details
                    ? JSON.parse(BiInvoice.info?.other_details)
                    : {}
                ).includes("comment")
                  ? JSON.parse(BiInvoice.info?.other_details).comment
                  : ""}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"button"}>Subtotal</Typography>
          <br />
          <Typography variant={"button"}>Delivery Fee</Typography>
          <br />
          <Typography variant={"button"}>COD Charge</Typography>
          <br />
          <Typography variant={"button"} sx={{ fontWeight: "bold" }}>
            Total
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            textAlign: "right",
          }}
        >
          <Typography variant={"button"}>
            {BiInvoice.info?.sub_total}৳
          </Typography>
          <br />
          <Typography variant={"button"}>
            {BiInvoice.info?.shipping_total_cost}৳
          </Typography>
          <br />
          <Typography variant={"button"}>
            {BiInvoice.info?.cod_charge}৳
          </Typography>
          <br />
          <Typography variant={"button"} sx={{ fontWeight: "bold" }}>
            {BiInvoice.info?.total_amount}৳
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default BiInvoiceDialog;
