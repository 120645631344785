import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import DropZone from "../../components/DropZone";
import ShowError from "../../components/ShowError";
import snackContext from "../../context/snackProvider";
import { useUpdateCategory } from "../../query/category";
import { getAttachment } from "../../service/instance";
import tableOptionsStyle from "../../style/tableOptions";
import { responseHandler } from "../../utilities/response-handler";

const UpdateCategory = ({ open, onClose, info }) => {
  const snack = React.useContext(snackContext);
  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category_id: info.id,
      title_en: info.title_en,
      photo: {
        preview: getAttachment(info.photo.slice(1)),
        previous: true,
      },
    },
  });

  const { mutateAsync, isLoading } = useUpdateCategory();

  const handleCreate = async (data) => {
    if (data.photo.previous) delete data.photo;
    const res = await responseHandler(() => mutateAsync(data));
    if (res.status) {
      snack.createSnack(res.msg);
      reset();
      onClose();
    } else {
      snack.createSnack(res.msg, "error");
    }
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={"right"}
      PaperProps={{
        sx: {
          width: "95vw",
          maxWidth: "470px",
        },
      }}
    >
      <form onSubmit={handleSubmit(handleCreate)}>
        <List
          disablePadding
          sx={{
            flex: 1,
          }}
        >
          <ListItem
            // disablePadding
            sx={{
              px: 2,
            }}
          >
            <ListItemText
              primary={"Update Category"}
              secondary={"Update Category Information"}
            />
            <IconButton size={"small"} color={"black"} onClick={onClose}>
              <MdClose />
            </IconButton>
          </ListItem>
          <Divider />
          <ListItem>
            <Box
              sx={
                {
                  // mx: "auto",
                }
              }
            >
              <DropZone
                maxSize={10242880}
                defaultValue={watch("photo")}
                onChange={(data) => {
                  setValue("photo", data);
                  return true;
                }}
                onDelete={() => {
                  setValue("photo", undefined);
                  return true;
                }}
              />
            </Box>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            Category Name
            <InputBase
              sx={tableOptionsStyle}
              placeholder={"Enter Category Name"}
              {...register("title_en", {
                required: true,
              })}
              fullWidth
            />
            <ShowError err={errors.title_en || errors.title_ba} />
            {/* <InputBase
                sx={tableOptionsStyle}
                placeholder={"Enter Category Name [Bengali]"}
                {...register("title_ba")}
                fullWidth
              /> */}
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              rowGap: 1,
              columnGap: 1,
            }}
          >
            <Button
              variant="contained"
              type={"submit"}
              disabled={isLoading}
              fullWidth
            >
              Upload
            </Button>
          </ListItem>
        </List>
      </form>
    </Drawer>
  );
};

export default UpdateCategory;
