import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useGetBiInvoice } from "../../query/inovoice";
import BiInvoiceDialog from "./biInoiceDialog";

const biInvoiceContext = React.createContext();

export const BiInvoiceProvider = ({ children }) => {
  // Get Search Params
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Parameter State
  const [biInvoiceInfo, setBiInvoiceInfo] = React.useState();

  // Fetch Data
  const {
    data: biInvoiceData,
    isLoading,
    isError,
    isRefetching,
  } = useGetBiInvoice(searchParams.get("bi-invoice"));
  // Set Invoice to State
  React.useEffect(() => {
    if (isLoading || isError) return;
    if (!biInvoiceData?.data.status) return;
    setBiInvoiceInfo(biInvoiceData.data.value);
  }, [isLoading, isRefetching]);

  return (
    <biInvoiceContext.Provider
      value={{
        info: biInvoiceInfo,
        isLoading: isLoading,
        showBiInvoice: (orderId) => {
          navigate({
            search: `?bi-invoice=${orderId}`,
          });
        },
      }}
    >
      {children}
      <>
        <Backdrop
          open={isLoading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          onClick={() => {
            navigate({
              search: ``,
            });
            setBiInvoiceInfo();
          }}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
        <BiInvoiceDialog
          open={!!biInvoiceInfo}
          onClose={() => {
            navigate({
              search: ``,
            });
            setBiInvoiceInfo();
          }}
        />
      </>
    </biInvoiceContext.Provider>
  );
};

export default biInvoiceContext;
